import { fabric } from './fabric.min.js';

const pencilWidth = 5;
const eraserWidth = 30;
const defaultColor = '#00008b'
const opacityClass = 'opacity-50';

export default class OverlayCanvas {
  constructor() {
    this.enableOverlayCanvasBtn = '#show-overlay-canvas-btn';
    this.enableOverlayCanvasMessage = '#enable-overlay-canvas-message';
    this.disableOverlayCanvasMessage = '#disable-overlay-canvas-message';
    this.overlayCanvasContainer = '.overlay-canvas-container';
    this.canvasContainer = '.canvas-container'; // generated by fabric.js
    this.overlayCanvas = '#overlay-canvas';
    this.fabricOptions = '.fabric-options';
    this.eraserSelector = '#fabric-eraser';
    this.pencilSelector = '#fabric-pencil';
    this.handSelector = '#fabric-hand';
    this.colorPickerSelector = '#fabric-color-picker';
    this.fabricOptionsBtn = '.fabric-options button';
    this.selectedColor = defaultColor;
    this.canvas = null;
  }

  setup() {
    this.resizeOverlayCanvasContainer();
    this.initOverlayCanvas();
    this.handleFabricOptions();

    $(this.enableOverlayCanvasBtn).on('click', () => {
      this.toggleOverlayCanvas();
      $('.question-section').hide();
    });

    const resizeObserver = new ResizeObserver(() => {
      this.resizeOverlayCanvasContainer()
    })
    resizeObserver.observe(document.body);
  }

  toggleOverlayCanvas() {
    if ($(this.enableOverlayCanvasMessage).is(':visible')) {
      $(this.enableOverlayCanvasMessage).hide();
      $(this.disableOverlayCanvasMessage).show();
      $(this.overlayCanvasContainer).show();
      $(this.fabricOptions).removeClass('d-none');
    } else {
      $(this.enableOverlayCanvasMessage).show();
      $(this.disableOverlayCanvasMessage).hide();
      $(this.overlayCanvasContainer).hide();
      $(this.fabricOptions).addClass('d-none');
      this.clearOverlayCanvas();
    }
  }
  
  resizeOverlayCanvasContainer() {
    $(this.overlayCanvasContainer).width($(document).width());
    $(this.overlayCanvasContainer).height($(document).height());

    if (this.canvas) {
      this.canvas.setDimensions({
        width: $(document).width(),
        height: $(document).height(),
      });
    }
  }

  initOverlayCanvas() {
    this.canvas = new fabric.Canvas(document.querySelector(this.overlayCanvas));
    this.canvas.isDrawingMode = true;
    this.canvas.freeDrawingCursor = 'pointer';
    this.setPencilBrush(this.canvas);

    this.canvas.setDimensions({
      width: $(document).width(),
      height: $(document).height(),
    });
  }

  setPencilBrush() {
    this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
    this.canvas.freeDrawingBrush.width = pencilWidth;
    this.canvas.freeDrawingBrush.color = this.selectedColor;
  }

  setEraserBrush() {
    this.canvas.freeDrawingBrush = new fabric.EraserBrush(this.canvas);
    this.canvas.freeDrawingBrush.width = eraserWidth;
  }

  handleFabricOptions() {
    $(this.eraserSelector).on('click', () => {
      $(this.canvasContainer).css('pointer-events', 'auto')
      this.handleToggleButton($(this.eraserSelector));

      this.setEraserBrush();
    })

    $(this.pencilSelector).on('click', () => {
      this.setPencilBrush();
      $(this.canvasContainer).css('pointer-events', 'auto')
      this.handleToggleButton($(this.pencilSelector));
    })
    
    const $colorPickerSelector = $(this.colorPickerSelector);
    $colorPickerSelector.val(defaultColor)
    $colorPickerSelector.on('change', (e) => {
      this.selectedColor = $(e.target).val();
      this.canvas.freeDrawingBrush.color = this.selectedColor;
    })

    $(this.handSelector).on('click', () => {
      $(this.canvasContainer).css('pointer-events', 'none')
      this.handleToggleButton($(this.handSelector));
    })
  }

  clearOverlayCanvas() {
    $(this.fabricOptions).addClass('d-none');
    this.canvas.clear();
  }

  handleToggleButton($element) {
    $(this.fabricOptionsBtn).addClass(opacityClass);
    $element.removeClass(opacityClass)
  }
}
